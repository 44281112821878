import React from 'react';
import Helmet from 'react-helmet';

export default class SEO extends React.Component {
  render() {
    const { title, description, keywords = [] } = this.props;
    return (
      <Helmet title={title} meta={[{ name: 'description', content: description }]}>
        <meta name="description" content={description} />
        <meta name="facebook-domain-verification" content="i6w7rc7o7aeboap3zzl1mtgpubxbqs" />
        {keywords.length && <meta name="keywords" content={keywords.join(',')} />}
      </Helmet>
    );
  }
}
